.headerContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  > .unLogin {
    background: linear-gradient(90deg, #58a5ff 0%, #9873ff 14%, #4931da 81%, #b341f9 96%);
    text-align: center;
    height: 45px;
    line-height: 45px;
    color: var(--app-text-color);
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 1px;

    & > span:nth-child(1) {
      cursor: pointer;
      border-bottom: 1px solid var(--app-text-color);
    }
  }

  .globalHeaderWarp {
    height: var(--pietra-global-header-heigth);
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    background: var(--foreground-rgb);
    padding: 0 2rem;
    transition: padding 0.2s ease;

    &.shopPage {
      padding-left: 25px;
    }

    .navSection {
      display: flex;
      align-items: center;
      color: var(--app-text-color);

      &:first-child {
        justify-content: flex-start;
      }

      &:nth-child(2) {
        justify-content: center;
      }

      &:last-child {
        justify-content: flex-end;
      }

      > .login {
        height: 2.25rem;
        border-radius: 1.125rem;
        padding: 0 1.5rem;
        color: var(--foreground-rgb);
        background-color: var(--app-primary-color);
        font-size: 0.875rem;
      }

      .emailIcon {
        height: 2.25rem;
        aspect-ratio: 1;
        border-radius: 1.125rem;
        padding: 0;
        margin: 0;
        background: #2db3ff;
        color: #fff;
      }

      > .navSearch {
        display: flex;
        align-items: center;
        width: 100%;

        .sparkStone {
          cursor: pointer;
          width: 50px;
          height: 50px;
          object-fit: contain;
          border-radius: 0;
          background: none;
          margin-right: 1rem;
        }
      }

      > .cartIcon {
        cursor: pointer;
        margin-left: 20px;
        transform: translateY(2px);
      }
    }

    .navTitleContainer {
      cursor: pointer;

      .catchPhrase {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0;
        white-space: nowrap;

        .retroTitle {
          font-size: 26px;
        }

        .logo {
          display: inline-block;
          width: 32px;
          height: 32px;
        }

        .name {
          display: inline-block;
          height: 32px;
          width: auto;
        }

        > .poweredBy {
          margin-top: 3px;
          font-size: 12px;

          > .logo {
            margin-left: 10px;

            > .pietra {
              width: 40px;
              height: auto;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
}

