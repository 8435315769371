.mobileHeaderWarp {
  position: fixed;
  transition: top 0.3s ease;
  left: 0;
  width: 100%;
  background: var(--foreground-rgb);

  color: white;
  z-index: 4;
  text-align: center;
  .mobile-banner {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    justify-items: center;
    text-align: center;
  }

  > .navToolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;

    > .navToolbarSection {
      display: flex;
      align-items: center;

      &:nth-child(1) {
        justify-content: flex-start;
      }

      &:nth-child(2) {
        justify-content: center;
      }

      &:nth-child(3) {
        justify-content: flex-end;
      }

      > .logo {
        > .pietra {
          width: 32px;
          height: 32px;
        }
      }

      > .searchIcon {
        margin-left: 16px;
      }

      > .cartIcon {
        transform: translateY(2px);
      }

      > .userContent {
        display: block;
      }
    }
  }
}

