.mainBackContent {
  color: var(--app-text-color);
  padding: 0 0 2rem;
  overflow-x: hidden;
  background-color: var(--foreground-rgb);
}

.mainContent {
  max-width: 1060px;
  padding: 1rem;
  margin: 0 auto;
}

