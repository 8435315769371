.globalFooterWarp {
  background: var(--foreground-rgb);
  padding-bottom: 65px;

  ul {
    list-style-type: none;
  }

  .line {
    border-top: 1px solid var(--app-text-60-color);
    padding-bottom: 50px;
    background-color: var(--foreground-rgb);
    //透明度60%
    opacity: 0.3;
  }

  .mainConetent {
    display: grid;
    grid-template-columns: 280px 1fr;
    grid-column-gap: 30px;

    > .logConatiner {
      > p {
        margin-top: 16px;
        color: var(--app-text-60-color);
      }
    }

    > .otherConatiner {
      text-align: right;

      > .linkContainer {
        color: var(--app-text-color);
        display: flex;
        justify-content: space-between;

        .linkGroup {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 20px;
        }

        .linkTitle {
          font-weight: bold;
          margin-bottom: 12px;
          display: block;
        }

        .linkChildren {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        .linkChild {
          color: var(--app-text-60-color);
          font-size: 14px;
          margin-bottom: 8px;
          transition: color 0.3s;
          text-decoration: none;
          display: inline-block;

          &:hover {
            color: var(--app-primary-color);
          }
        }
      }

      > .copyrightContainer {
        margin-top: 16px;
        font-size: 16px;
        color: var(--app-text-60-color);
      }
    }
  }
  .copyrightLine {
    border-top: 1px solid var(--app-text-60-color);
    opacity: 0.3;
    background-color: var(--foreground-rgb);
  }
  .copyright {
    margin-top: 36px;
    font-size: 14px;
    color: var(--app-text-60-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: 900px) {
  .globalFooterWarp {
    .mainConetent {
      grid-template-columns: 280px 1fr;
      grid-row-gap: 0;

      > .otherConatiner {
        grid-template-columns: 1fr 1fr 1.5fr;
        grid-row-gap: 0;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .globalFooterWarp {
    .mainConetent {
      grid-template-columns: 1fr;
      grid-row-gap: 30px;

      > .otherConatiner {
        grid-template-columns: 1fr;
        grid-row-gap: 30px;
        text-align: left;

        > .linkContainer {
          flex-direction: column;

          .linkGroup {
            margin-bottom: 24px;
          }
        }
      }
    }
    .copyright {
      margin-top: 36px;
      font-size: 14px;
      color: var(--app-text-60-color);
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

